/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --cor-primaria: #042e1a; /* Verde escuro */
  --cor-secundaria: #cddbc2; /* Verde claro */
  --cor-terciaria: #546048; /* Verde musgo */
  --cor-destaque: #edf5e8; /* Branco (verde muito claro) */
  --cor-alternativa: #ced1ca; /* Cinza */
  --cor-texto-primario: #333;
  --cor-texto-secundario: #777;
  --cor-texto-terciario: #ccc;
  --cor-texto-alternativo: #fff;
  --cor-texto-destaque: #999;
}

body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 18px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: var(--cor-destaque);
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: var(--cor-destaque);
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
.learn-more-text {
  letter-spacing: 1px;
  font-size: 15px;
  align-items: center;
  color: #fff;
  text-shadow: '1px 2px 2px var(--cor-primaria)';
}
/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: var(--cor-secundaria);
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}
#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  /*text-transform: uppercase;*/
  color: #555;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05cap;
  padding: 8px 2px;
  border-radius: 0;
  margin: 16px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: var(--cor-destaque);
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: var(--cor-destaque);
}
.section-title {
  margin-bottom: 70px;
}
.section-title h1 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h1::after {
  position: absolute;
  content: "";
  background: var(--cor-secundaria);
  height: 2px;
  width: 100%;
  bottom: 0;
  margin-left: 0px;
  left: 0%;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}

.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #50bc5f;
  padding: 14px 34px;
  letter-spacing: 2px;
  margin: 5%;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom.btn {
  border-width: 2px;
}
.btn-custom.btn:hover,
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #50bc5f;
  background-image: none;
  background-color: #fff;
  border-color: #50bc5f;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
/* Header Section */
.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(img/divorcio2.jpg) center bottom no-repeat;
  background-color: var(--cor-secundaria); /*#e5e5e5*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover; /*cover*/
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(4, 46, 26, 0.6);
}
.intro h1 {
  font-family: "Helvetica", sans-serif;
  letter-spacing: 0.25cap;
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 10px;
  text-shadow: 1px 2px 2px var(--cor-primaria);
}
.intro h1 span {
  font-weight: 700;
  color: #5ca9fb;
}
.intro h2 {
  color: #fff;
  text-shadow: #042e1a;
  box-shadow: inset;
}
.intro h2 span {
  font-weight: 700;
  color: #5ca9fb;
}
/* .intro p {
  color: var(--cor-primaria);
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.25cap;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
} */
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.15cap;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-shadow: 1px 2px 2px var(--cor-primaria);
}
header .intro-text {
  padding-top: 120px;
  padding-bottom: 20px;
  text-align: center;
}
@media (max-width: 767px) {
  .intro h1 {
    font-size: 22px;
  }
  .intro .section-title {
    margin-bottom: auto;
  }
  .intro p {
    font-size: 14px;
    line-height: 14px;
  }
  span {
    font-size: 12px;
    line-height: 12px;
  }
}
/* Features Section */
#features {
  background: #f6f6f6;
  padding: 25px 0 25px 0;
}
#features h2 {
  margin-top: 40px;
}
#features .section-title {
  margin-bottom: 10px;
}
#features i.fa {
  font-size: 38px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
/* Presentation Section */
#presentation {
  padding: 75px 0;
}
#presentation h3 {
  font-size: 22px;
  margin: 10px 0 0 0;
  text-transform:initial;
  color: var(--cor-terciaria);
}
#presentation h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#presentation h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#presentation .presentation-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#presentation .presentation-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: var(--cor-primaria);
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#presentation img {
  width: 100%;
  margin-top: 15%;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  border-bottom-right-radius: 10cap;
  border-top-left-radius: 5cap;
}
#presentation p {
  line-height: 24px;
  margin: 30px 0;
}
@media (max-width: 768px) {
  #presentation img {
    margin: 50px 0;
  }
}
/* About Section */
#about {
  background-color: white;
  padding: 85px 0 40px 0;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: var(--cor-primaria);
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 100%;
  margin-top: 0px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  border-bottom-right-radius: 10cap;
  border-top-left-radius: 5cap;
  /*margin-top: 10%;*/
  margin-bottom: 10%;
}
#about .img-escritorio  {
  background: #fff;
}
#about .img-especialista  {
  background: #fff;
  border-bottom-right-radius: 5cap;
  border-top-left-radius: 10cap;
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}
@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
}
/* Services Section */
#services {
  padding: 40px 0 100px 0;
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-terciaria) 100%);
  color: #fff;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: #fff;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, var(--cor-secundaria) 0%, var(--cor-terciaria) 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, var(--cor-primaria) 0%, var(--cor-secundaria) 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: rgba(255, 255, 255, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}
#services .section-title {
  margin-bottom: 30px;
}
/* Portfolio Section */
#portfolio {
  padding: 100px 0;
}
.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    var(--cor-primaria) 0%, 
    var(--cor-secundaria) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}
/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}
/* Faq Section */
#faq {
  background: #f6f6f6;
  padding: 50px 0 25px 0;
}

#faq .learn-more-text {
  color:#333;
}

#faq .learn-more-link {
  color:#333;
  text-underline-offset: 5px;
}

#faq .section-title {
  margin-bottom: 0px;
}
/* Card Section */
#card {
  background: #f6f6f6;
  padding: 25px 0 25px 0;
}

#card .learn-more-text {
  color:#fff;
  text-shadow: 1px 2px 2px var(--cor-primaria)
}

#card .learn-more-link {
  color:#fff;
  text-underline-offset: 5px;
}
/* Contact Section */
#contact {
  padding: 75px 0 0 0;
}
#contact h4 {
  margin: 5px 0;
}
#contact .contact-img {
  width: 240px;
}
#contact .thumbnail {
  background: transparent;
  border: 0;
}
#contact .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}
/* Footer Section*/
#footer {
  background: #f6f6f6;
  padding: 30px 0;
}
#footer p {
  color: #888;
  font-size: 14px;
}
#footer a {
  color: var(--cor-destaque);
}
#footer a:hover {
  border-bottom: 2px solid var(--cor-destaque);
}

#contact p {
  word-spacing: 0cap;
  letter-spacing:'2px';
}

#contact a {
  color: var(--cor-texto-primario);
}

#contact a:hover {
  color: var(--cor-texto-destaque);
  text-decoration: none;
}

#contact a:focus {
  color: var(--cor-texto-destaque);
  text-decoration: none;
}

@media (min-width: 992px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: fit-content;
  }
}